<template>
    <div>

        <b-card no-body>
            <div class="m-2">
                <h1>{{i18nT(`Timesheets`)}}</h1>
                <b-row>
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group :label="i18nT(`From`)">
                            <date-picker
                                v-model="from"
                                :placeholder="i18nT(`From Date`)"
                                :max="to"
                                :start-weekday="1"
                                :locale="`en-UK`"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group :label="i18nT(`To`)">
                            <date-picker
                                reset-button
                                v-model="to"
                                :placeholder="i18nT(`To Date`)"
                                :min="from"
                                :start-weekday="1"
                                :locale="`en-UK`"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group :label="i18nT(`Status`)">
                            <b-form-select
                                v-model="status"
                                :options="approvalStatusOptions"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-card>



        <div class="mb-1">
            <span class="text-muted">{{i18nT(`Showing`)}} {{ dataMeta.from }} {{i18nT(`to`)}} {{ dataMeta.to }} {{i18nT(`of`)}} {{ dataMeta.of }} {{i18nT(`entries`)}}</span>
        </div>
        <b-card no-body>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12">
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block"
                        />
                        <b-button
                            variant="primary"
                            v-b-modal.modal-request
                            class="float-right"
                        >
                            {{i18nT(`Add New`)}}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
<!--              <b-row>-->
<!--            <b-col cols="12" class="text-align-right">-->
<!--              <div class="sk-column-control">-->
<!--                <b-dropdown-->
<!--                    no-caret-->
<!--                    variant="button"-->
<!--                    toggle-class="p-0"-->
<!--                    :right="true"-->
<!--                    class="pr-1 pl-1 pb-2 mb-1 d-flex sk-column-filter"-->
<!--                    v-b-tooltip = "i18nT(`Customize columns`)"-->
<!--                >-->
<!--                  <template #button-content>-->
<!--                    <feather-icon-->
<!--                        icon="SettingsIcon"-->
<!--                        size="18"-->
<!--                        class="text-right"-->
<!--                    />-->
<!--                  </template>-->

<!--                  <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">-->
<!--                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>-->
<!--                  </div>-->
<!--                </b-dropdown>-->
<!--              </div>-->
<!--            </b-col>-->

<!--          </b-row>-->
            <div>
                <b-table
                    ref="refDocumentListTable"
                    :items="fetchRequests"
                    responsive
                    :fields="computedColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    show-empty
                    :empty-html="emptyHtml"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    @refreshed="onTableRefresh"
                    v-model="currentItems"
                    style="min-height: 500px"
                >
                    <template #head(action)="data">
                        <span>{{ data.label.toUpperCase() }}</span>
                        <div class="sk-column-control">
                            <b-dropdown
                                no-caret
                                variant="button"
                                toggle-class="p-0"
                                :right="true"
                                class="pr-1 pl-1 d-flex sk-column-filter"
                                v-if="betaFeature"
                                v-b-tooltip = "i18nT(`Customize columns`)"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="SettingsIcon"
                                        size="18"
                                        class="text-right"
                                    />
                                </template>

                                <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>
                    <template #cell(day)="data">
                        <span>
                          <strong>{{data.item.Day | date}}</strong>
                        </span>
                        <feather-icon
                            v-if="data.item.Notes"
                            icon="InfoIcon"
                            size="16"
                            class="align-middle text-body ml-1"
                            v-b-tooltip.hover.right="data.item.Notes"
                        />
                    </template>

                    <template #cell(totalHours)="data">
                            {{ data.item.TotalHours }}h <span
                            v-if="data.item.TotalMinutes">{{ data.item.TotalMinutes }} min</span>
                    </template>

                    <template #cell(status)="data">
                        <span>
                          {{ getStatusTitle(data.item.ApprovalStatus) }}
                          <feather-icon
                              v-if="data.item.RejectedReason"
                              icon="InfoIcon"
                              size="16"
                              class="align-middle text-body"
                              v-b-tooltip.hover.right="data.item.RejectedReason"
                          />
                        </span>
                    </template>

                    <template #cell(submittedOn)="data">
                        <h5>
                            {{data.item.CreatedAt | date}}
                        </h5>
                    </template>

                    <template #cell(action)="data">
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0"
                            no-caret
                            v-if="data.item.ApprovalStatus !== 1
              "
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item
                                v-if="data.item.ApprovalStatus == 2"
                                @click="rejectReason(data)"
                            >
                                <feather-icon icon="InfoIcon" />
                                <span class="align-middle ml-50">{{i18nT(`Rejected Reason`)}}</span>
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-if="data.item.ApprovalStatus != 2"
                                @click="onEdit(data)"
                            >
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-if="data.item.ApprovalStatus != 2"
                                @click="onDelete(data)"
                            >
                                <feather-icon icon="Trash2Icon" />
                                <span class="align-middle ml-50">{{i18nT(`Delete`)}}</span>
                            </b-dropdown-item>

                        </b-dropdown>

                    </template>
                </b-table>

                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                        >
              <span class="text-muted">{{i18nT(`Showing`)}} {{ dataMeta.from }} {{i18nT(`to`)}} {{ dataMeta.to }} {{i18nT(`of`)}}
                {{ dataMeta.of }} {{i18nT(`entries`)}}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>
        <!-- MODAL -->

        <b-modal
            id="modal-reason"
            :title="i18nT(`Reason for rejection`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
        >
            <p>{{reason}}</p>
        </b-modal>
        <b-modal
            id="modal-request"
            :title="i18nT(`Timesheet`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationRequest"
        >
            <validation-observer ref="requestForm">
                <b-form>
                    <b-row>
                        <b-col sm="12">
                            <b-row class="mt-2">
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Day`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Day"
                                            rules="required"
                                        >
                                            <date-picker
                                                reset-button
                                                v-model="requestDay"
                                                :placeholder="i18nT(`Day`)"
                                                :start-weekday="1"
                                                :locale="`en-UK`"
                                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                                :state="errors.length > 0 ? false:null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-row
                                        v-for="(timesheetHour, idx) in timesheetHours"
                                        :key="idx"
                                    >
                                        <b-col md="10">
                                            <b-row>
                                                <b-col>
                                                    <b-form-group
                                                        label-for="message"
                                                        class="required"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="From"
                                                            rules="required"
                                                        >
                                                            <b-form-timepicker
                                                                v-model="timesheetHour.FromTime"
                                                                locale='en'
                                                                :hour12="false"
                                                                :placeholder="i18nT(`From`)"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <span class="pt-50">:</span>
                                                <b-col>
                                                    <b-form-group
                                                        label-for="message"
                                                        class="required"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="End"
                                                            rules="required"
                                                        >
                                                            <b-form-timepicker
                                                                v-model="timesheetHour.ToTime"
                                                                locale='en'
                                                                :hour12="false"
                                                                :placeholder="i18nT(`End`)"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>

                                        <b-col md="2">
                                            <b-button
                                                v-if="idx === timesheetHours.length - 1"
                                                variant="primary"
                                                class="btn-icon rounded-circle"
                                                @click="addNewTimesheetHour"
                                            >
                                                <feather-icon icon="PlusIcon" />
                                            </b-button>
                                            <b-button
                                                v-else
                                                variant="danger"
                                                class="btn-icon rounded-circle"
                                                @click="removeTimesheetHour(idx)"
                                            >
                                                <feather-icon icon="MinusIcon" />
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Note`)"
                                        label-for="message"
                                    >
                                        <b-form-textarea
                                            v-model="requestNote"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BTable,
  // BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  // BListGroup, BListGroupItem,
  BFormSelect,
  BModal,
  BFormTimepicker,
  BForm,
  BFormInput,
  BFormTextarea,
  VBTooltip, BFormCheckbox
  // VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTimesheet from './useTimesheet'
import { avatarText } from '@core/utils/filter'
import { dictToSelectArray } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DatePicker from '@core/components/DatePicker.vue'
import useAuth from "@/auth/useAuth";

// import _ from 'lodash'

export default {
    components: {
      BFormCheckbox,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        // BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BListGroup,
        // BListGroupItem,
        vSelect,
        BFormSelect,
        BModal,
        BFormTimepicker,
        BForm,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormTextarea,
        DatePicker
        // ToastificationContent
        // VBModal
    },
    directives: {
        Ripple,
        // 'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            timesheetHours: [
                {
                    FromTime: '00:00:00',
                    ToTime: '00:00:00'
                }
            ],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            audienceGroups: [],
            requestDay: null,
            requestHours: null,
            requestNote: null,
            approvalStatusOptions: [],
            required,
            currentTimesheet: null,
            reason: '',
            avalableFields: [
              {label: this.i18nT(`Date`), key: 'day', visible: true},
              {label: this.i18nT(`Submitted on`), key: 'submittedOn', visible: true},
            ],
        }
    },
    created() {
        this.getStatus()
        let storePreferences = useAuth.getColumnPreferences('TimeSheetsEmployee');
          if(storePreferences) {
            this.avalableFields = storePreferences
          }
    },
    methods: {
        onDelete(leave) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`timesheets?id=${leave.item.Id}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The leave has been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(data) {
            this.currentTimesheet = data.item
            this.timesheetHours = data.item.TimesheetHours.length
                ? data.item.TimesheetHours
                : [{ FromTime: '00:00:00', ToTime: '00:00:00' }]
            this.requestDay = data.item.Day
            this.requestHours = data.item.TotalHours
            this.requestNote = data.item.Notes
            this.$bvModal.show('modal-request')
        },
        addNewTimesheetHour() {
            this.timesheetHours.push({
                FromTime: this.timesheetHours[this.timesheetHours.length - 1].ToTime,
                ToTime: '00:00:00'
            })
        },
        removeTimesheetHour(idx) {
            this.timesheetHours.splice(idx, 1)
        },
        getStatus() {
            this.$http.get(`timesheets/approvalStatusFilter`).then(({ data }) => {
                this.approvalStatusOptions = dictToSelectArray(data.data)
            })
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onBulkCopy() {
            this.$http
                .post(`surveys/copySurvey?id=${this.selectedItems.join(',')}`)
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`surveys?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`Timesheets successfully deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onPutInFolder(data) {
            console.log(data)
            // this.$bvModal.show('modal-pipeline');
        },
        validationRequest(event) {
            event.preventDefault()
            this.$refs.requestForm.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()
                    if (this.currentTimesheet) {
                        formData.append('id', this.currentTimesheet.Id)
                    }
                    formData.append('Notes', this.requestNote)
                    formData.append('Day', this.requestDay)
                    // formData.append("TotalHours", this.requestHours);
                    formData.append('HalfDay', 0)
                    this.timesheetHours.forEach((t, idx) => {
                        if (t.Id) formData.append(`timesheet_hours_id[${idx}]`, t.Id)
                        formData.append(
                            `timesheet_hours_from[${idx}]`,
                            t.FromTime.substr(0, 5)
                        )
                        formData.append(`timesheet_hours_to[${idx}]`, t.ToTime.substr(0, 5))
                    })

                    this.$http
                        .post('timesheets', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.$bvModal.hide('modal-request')
                            this.requestNote = null
                            this.requestDay = null
                            this.requestHours = null
                            this.currentTimesheet = null

                            this.refetchData()
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        getStatusTitle(statusId) {
            try {
                return this.approvalStatusOptions.find(
                    option => option.value === statusId + ''
                ).text
            } catch (ex) {
                console.log(ex)
                return ''
            }
        },
        rejectReason(data) {
            this.reason = data.item.RejectedReason
            this.$bvModal.show('modal-reason')
        }
    },
    setup() {
        const {
            fetchRequests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            status,
            surveys,
            refetchData
        } = useTimesheet()

        return {
            fetchRequests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            status,
            avatarText,
            surveys,
            refetchData
        }
    },
    computed: {
        emptyHtml() {

            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                + this.i18nT(`Here, you can create and manage your timesheets. No timesheets have been created yet.`) + "</h4>"
                +"<button class='btn btn-outline-secondary mt-2'  id='btn-showModal'>" + this.i18nT(`Create timesheet`) + "</button>"
        },
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('TimeSheetsEmployee', this.avalableFields);
            return col
        },
    },
    updated(){
        const timeSheetBtn = document.querySelector('#btn-showModal')
        if(timeSheetBtn) {
            timeSheetBtn.addEventListener('click', ()=> this.$bvModal.show('modal-request'));
        }
    },

}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
.section-title {
    font-size: 11px;
}
.document-name {
    display: inline;
    line-height: 32px;
}
.document-actions-bar {
    float: right;
}
.ql-editor {
    min-height: 200px;
}
.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}
.cv-card {
    min-height: 180px;
    span {
        font-size: 12px;
    }
}
</style>
